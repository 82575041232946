<template>
  <p-table
    v-model="tableState"
    :header="header"
    :body="bodyData"
    :pagination="pagination"
    :searchable="searchable"
    :searchable-placeholder="searchablePlaceholder"
    :searchable-autofocus="searchableAutofocus"
    :total-items="filteredBodyData.length"
    :has-bulk-selections="hasBulkSelections"
    :hide-header="hideHeader"
    :overflow="overflow"
    :skeleton-loader="skeletonLoader"
    :skeleton-loader-rows="skeletonLoaderRows"
    :skeleton-loader-text-lines="skeletonLoaderTextLines"
    :reorder="reorder"
    @sort="onSort"
  >
    <template #bodyCell="{ row, column, header }">
      <slot v-bind:row="row" v-bind:header="header" v-bind:column="column" name="bodyCell">
        <template v-if="!Array.isArray(column)">{{ column }}</template>
      </slot>
    </template>
    <template #toolbar>
      <slot name="toolbar" />
    </template>

    <template #empty>
      <slot name="empty" />
    </template>
  </p-table>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TableBody, TableHeader, TableState } from '@/components/ui/Table.vue';
import { PropType } from 'vue';

@Component({
  inheritAttrs: false
})
export default class TableClient extends Vue {
  @Prop({ type: Array as PropType<TableHeader[]>, required: false, default: undefined })
  public readonly header?: TableHeader[];
  @Prop({ type: Array as PropType<TableBody[]>, required: true })
  public readonly body!: TableBody[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly pagination!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly searchable!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly searchablePlaceholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly searchableAutofocus!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasBulkSelections!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly skeletonLoader!: boolean;
  @Prop({ type: Number, required: false, default: undefined }) public readonly skeletonLoaderRows?: number;
  @Prop({ type: Number, default: 1, required: false }) public readonly skeletonLoaderTextLines!: number;
  @Prop({ type: Number, required: false, default: 10 }) public readonly defaultItemsPerPage!: number;
  @Prop({ type: Boolean, required: false, default: false }) public readonly hideHeader!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly overflow!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly reorder!: boolean;

  public tableState: TableState = {
    sorting: {
      column: '',
      direction: 'none'
    },
    searchTerm: '',
    itemsPerPage: this.defaultItemsPerPage ? this.defaultItemsPerPage : 10,
    currentPage: 1,
    bulkSelections: []
  };

  public get sortingDirection(): 'none' | 'asc' | 'desc' {
    return this.tableState.sorting.direction;
  }

  @Watch('tableState.searchTerm')
  public onSearchTermChange() {
    this.tableState.currentPage = 1;
  }

  public onSort(oldIndex: number, newIndex: number) {
    this.$emit('sort', oldIndex, newIndex);
  }

  public get filteredBodyData() {
    let sortedData = this.body;

    if (this.searchable && this.tableState.searchTerm.trim() !== '') {
      sortedData = this.sortBySearch(sortedData);
    }

    if (this.sortingDirection !== 'none') {
      sortedData = this.sortByHeaderDirection(sortedData);
    }

    return sortedData;
  }

  public get bodyData() {
    const sortedData = this.filteredBodyData;

    // If pagination is false, return all records
    if (!this.pagination) {
      return sortedData;
    }

    // If pagination is true, return the records for the current page
    const start = (this.tableState.currentPage - 1) * this.tableState.itemsPerPage;
    const end = start + this.tableState.itemsPerPage;

    return sortedData.slice(start, end);
  }

  private sortBySearch(data: TableBody[]) {
    let sortedData = data;

    sortedData = sortedData.filter((item) => {
      return Object.values(item).some(
        (value) =>
          (typeof value === 'string' || typeof value === 'number') &&
          value.toString().toLowerCase().includes(this.tableState.searchTerm.toLowerCase())
      );
    });

    return sortedData;
  }

  private sortByHeaderDirection(data: TableBody[]) {
    let sortedData = data;
    sortedData = sortedData.slice().sort((a, b) => {
      const aValue = a[this.tableState.sorting.column];
      const bValue = b[this.tableState.sorting.column];

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return this.sortingDirection === 'asc' ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortingDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return 0;
    });

    return sortedData;
  }
}
</script>
