<template>
  <div style="width: 100%">
    <p-table-client
      v-if="element.properties.clientSide"
      :header="header"
      :body="body"
      :searchable="element.properties.searchable"
      :searchable-placeholder="element.properties.searchablePlaceholder"
      :sortable="element.properties.sortable"
      :pagination="element.properties.paginating"
      :hide-header="element.properties.hideHeader"
      :overflow="element.properties.overflow"
      :has-bulk-selections="element.properties.bulkSelect"
      :reorder="element.properties.reorder"
      @sort="onSort"
    >
      <template #bodyCell="{ column }">
        <template v-if="Array.isArray(column)">
          <template v-if="column.length === 0">&nbsp;</template>
          <template v-else>
            <layout-element v-for="child in column" :element="child" :key="child.key" />
          </template>
        </template>
        <template v-else>{{ column }}</template>
      </template>

      <template #toolbar v-if="toolbar && toolbar.length > 0">
        <layout-element v-for="child in toolbar" :element="child" :key="child.key" />
      </template>
    </p-table-client>

    <p-table
      v-else
      :header="header"
      :body="body"
      :searchable="element.properties.searchable"
      :sortable="element.properties.sortable"
      :searchable-placeholder="element.properties.searchablePlaceholder"
      :pagination="element.properties.paginating"
      :has-bulk-selections="element.properties.bulkSelect"
      :total-items="element.properties.totalRows ?? body.length"
      :skeleton-loader="asyncLoading"
      :loading="loading"
      :skeleton-loader-rows="skeletonLoaderRows"
      :hide-header="element.properties.hideHeader"
      :overflow="element.properties.overflow"
      :modelValue="tableState"
      :reorder="element.properties.reorder"
      @sort="onSort"
      @update:modelValue="onTableStateChange"
      @bulk-interact="onBulkInteract"
    >
      <template #bodyCell="{ column }">
        <template v-if="Array.isArray(column)">
          <template v-if="column.length === 0">&nbsp;</template>
          <template v-else>
            <layout-element v-for="child in column" :element="child" :key="child.key" />
          </template>
        </template>
        <template v-else>{{ column }}</template>
      </template>

      <template #toolbar v-if="toolbar && toolbar.length > 0">
        <layout-element v-for="child in toolbar" :element="child" :key="child.key" />
      </template>
    </p-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTable, IElementTableValue, TriggerType } from '@/interfaces/element';
import { TableBody, TableHeader, TableHeaderSortingDirection, TableState } from '../ui/Table.vue';
import { Trigger } from '@/Trigger';
import { debounce } from 'lodash-decorators';
import { PropType } from 'vue';
import { EventBus } from '@/main';
import { autobind } from 'core-decorators';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-table'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementTable> }) public readonly element!: IElementTable;
  @Prop({ type: Boolean, required: false, default: false }) public readonly asyncLoading?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly async?: boolean;

  public loading = false;

  public mounted() {
    EventBus.$on('BLUEPRINT_UPDATED', this.onBlueprintUpdated);
  }

  public beforeDestroy() {
    EventBus.$on('BLUEPRINT_UPDATED', this.onBlueprintUpdated);
  }

  @autobind
  private onBlueprintUpdated() {
    this.loading = false;
  }

  public onSort(oldIndex: number, newIndex: number) {
    if (this.element.properties && this.element.properties.body) {
      const movedItem = this.element.properties.body.splice(oldIndex, 1)[0];
      this.element.properties.body.splice(newIndex, 0, movedItem);
    }

    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el
    );
  }

  public onTableStateChange(newState: TableState) {
    Vue.set<IElementTableValue>(this.element.properties, 'value', {
      search: newState.searchTerm,
      page: newState.currentPage,
      itemsPerPage: newState.itemsPerPage,
      sortBy: newState.sorting.column,
      sortByDirection: newState.sorting.direction === 'none' ? '' : newState.sorting.direction,
      bulk: newState.bulkSelections
    });
  }

  @Watch('element.properties.value.page')
  public onPageChange() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined' ? [...this.element.properties.name, 'page'] : undefined,
      this.element.properties.value?.page
    );
  }

  public onBulkInteract() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined' ? [...this.element.properties.name, 'bulk'] : undefined
    );
  }

  @Watch('element.properties.value.itemsPerPage')
  public onItemsPerPageChange() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined'
        ? [...this.element.properties.name, 'itemsPerPage']
        : undefined,
      this.element.properties.value?.itemsPerPage
    );
  }

  @Watch('element.properties.value.search')
  @debounce(1500)
  public onSearchChange() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    if (this.element.properties.value && this.element.properties.value.page !== 1) {
      this.element.properties.value.page = 1;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined' ? [...this.element.properties.name, 'search'] : undefined,
      this.element.properties.value?.search
    );
  }

  @Watch('element.properties.value.sortBy')
  @debounce(375)
  public onSortColumnChange() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined' ? [...this.element.properties.name, 'sortBy'] : undefined,
      this.element.properties.value?.sortBy
    );
  }

  @Watch('element.properties.value.sortByDirection')
  @debounce(375)
  public onSortDirectionChange() {
    if (!this.element.properties.clientSide) {
      this.loading = true;
    }

    Trigger.handle(
      {
        type: TriggerType.UPDATE,
        action: null,
        condition: null
      },
      this.$el,
      typeof this.element.properties.name !== 'undefined'
        ? [...this.element.properties.name, 'sortByDirection']
        : undefined,
      this.element.properties.value?.sortByDirection
    );
  }

  public get tableState(): TableState {
    let sortingDirection: TableHeaderSortingDirection = 'none';

    switch (this.element.properties.value?.sortByDirection) {
      case 'asc':
        sortingDirection = 'asc';
        break;

      case 'desc':
        sortingDirection = 'desc';
        break;
    }

    return {
      sorting: {
        column: this.element.properties.value?.sortBy ?? '',
        direction: sortingDirection
      },

      searchTerm: this.element.properties.value?.search ?? '',
      itemsPerPage: this.element.properties.value?.itemsPerPage ?? 10,
      currentPage: this.element.properties.value?.page ?? 1,
      bulkSelections: (this.element.properties.value?.bulk ?? []).map((value) => String(value))
    };
  }

  public get header(): TableHeader[] {
    return (
      this.element.properties.header?.map<TableHeader>((item) => {
        let align: 'top' | 'middle' | 'bottom' | undefined = undefined;

        switch (item.align) {
          case 'top':
            align = 'top';
            break;

          case 'middle':
            align = 'middle';
            break;

          case 'bottom':
            align = 'bottom';
            break;
        }

        return {
          id: item.value ?? '',
          label: item.text ?? '',
          value: item.value,
          width: item.width,
          verticalAlign: align,
          sortable: item.sortable,
          hasPadding: item.hasPadding
        };
      }) ?? []
    );
  }

  public get body(): TableBody[] {
    return (
      this.element.properties.body?.map<TableBody>((item) => {
        const row: TableBody = {};

        for (const column of this.header) {
          let rowItem = item.columns?.[column.id]?.column ?? '';

          if (Array.isArray(rowItem)) {
            rowItem = getElementsForRender(rowItem);
          }

          row[column.id] = rowItem;
        }

        if (this.element.properties.bulkSelect && !item.columns?.id.column) {
          throw new Error('Item key not found in row');
        }

        if (item.columns?.id?.column) {
          row.id = item.columns.id.column;
        }

        if (item.columns?.actions?.column) {
          row['actions'] = item.columns['actions'].column;

          if (Array.isArray(row['actions'])) {
            row['actions'] = getElementsForRender(row['actions']);
          }
        }

        return row;
      }) ?? []
    );
  }

  public get skeletonLoaderRows() {
    if (this.body.length > 0) {
      return this.body.length;
    }

    return this.tableState.itemsPerPage;
  }

  public get toolbar() {
    return getElementsForRender(this.element.toolbar ?? []);
  }
}
</script>
