<template>
  <p-row v-if="element.properties.secretValue" gap-size="small" align-items="flex-end">
    <p-tooltip
      :text="
        hiddenValue
          ? 'Your credentials have been saved and hidden to ensure security. You can edit but not view the current value.'
          : 'Once saved, credentials will no longer be visible. You can update the information at any time, but you can not view it for security reasons.'
      "
      position="right"
    >
      <p-form-input
        type="password"
        :label="element.properties.label"
        :help-text="element.properties.help"
        :help-in-tooltip="element.properties.helpInTooltip"
        :disabled="element.properties.disabled || hiddenValue"
        :size="element.properties.size"
        :readonly="element.properties.readonly"
        :error="element.properties.errors.join(', ')"
        :model-value="value"
        @update:modelValue="onUpdate"
      />
    </p-tooltip>
    <p-button size="medium" :disabled="element.properties.disabled || !hiddenValue" @click="hiddenValue = !hiddenValue"
      >Reset</p-button
    >
  </p-row>

  <p-form-input
    v-else
    type="password"
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :disabled="element.properties.disabled"
    :size="element.properties.size"
    :readonly="element.properties.readonly"
    :error="element.properties.errors.join(', ')"
    :model-value="value"
    @update:modelValue="onUpdate"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSecret } from '@/interfaces/element';
import { cancelBlueprintUpdate } from '@/utility';
import { autobind } from 'core-decorators';
import { Iframe } from '@/iframe';
import { debounce } from 'lodash-decorators';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-secret'
})
export default class extends Vue {
  @Prop() public element!: IElementSecret;

  private hiddenValue = true;
  private didInteract = false;
  private didInteractTimer: number | null = null;

  public internalValue = '';

  public get value(): string | undefined {
    if (this.hiddenValue && this.element.properties.secretValue) {
      return this.element.properties.secretValue;
    }

    if (this.element.properties.disabled && this.element.properties.secretValue) {
      return this.element.properties.secretValue;
    }

    return this.internalValue;
  }

  public mounted() {
    if (!this.element.properties.secretValue) {
      this.hiddenValue = false;
    }
  }

  @autobind
  public onUpdate(value: string) {
    if (this.hiddenValue) {
      return;
    }

    this.didInteract = true;

    // Cancel existing blueprint calls so that update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);

    this.internalValue = value;
    this.element.properties.value = value;
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>
