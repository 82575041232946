import { ILayoutTemplate } from '@/interfaces/template';

interface ILayoutElement<Type extends string, Properties = undefined> {
  properties: Properties & {
    id?: string;
  };
  scrollIntoView?: boolean;
  type: Type;
}

interface HasDisabled {
  disabled?: boolean;
}

interface HasToolbar {
  toolbar?: ILayoutTypeElement[];
}

interface HasDisabledArray<Value> {
  disabled?: Value[];
}

interface HasReadonly {
  readonly?: boolean;
}

interface HasSize {
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
}

interface HasLabel {
  label?: string;
}

interface HasAiAssistant {
  aiAssistant?: boolean;
}

interface HasGapSize {
  gapSize?: 'none' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
}

interface HasAsyncRender {
  // Asynchronous rendering job identifier
  asyncJob?: number;
}

interface HasPlaceholder {
  placeholder?: string;
}

interface HasTooltip {
  tooltip?: IElementTooltip | null;
}

interface HasTrigger {
  trigger?: ITrigger;
}

interface HasHelp {
  help?: string;
  helpInTooltip?: boolean;
}

interface ILayoutElementForm<Type extends string, Properties extends object, ValueType>
  extends ILayoutElement<Type, Properties> {
  properties: Properties & {
    name?: string[];
    errors: string[];
    value?: ValueType;
  };
}

export type ILayoutTypeElement =
  | IElementButton
  | IElementTextField
  | IElementTextarea
  | IElementMedia
  | IElementHidden
  | IElementTable
  | IElementCheckbox
  | IElementCheckboxes
  | IElementDivider
  | IElementRadio
  | IElementSelect
  | IElementSwitch
  | IElementColorpicker
  | IElementParagraph
  | IElementHeadline
  | IElementTagsInput
  | IElementCodeEditor
  | IElementBlueprint
  | IElementDevicePreview
  | IElementTextEditor
  | IElementImage
  | IElementSettingsNavigation
  | IElementIcon
  | IElementDatepicker
  | IElementBadge
  | IElementMessage
  | IElementLink
  | IElementSvgObject
  | IElementChart
  | IElementStepGuide
  | IElementSlider
  | IElementTimepicker
  | IElementAccordion
  | IElementRecaptcha
  | IElementGoogleMap
  | IElementCard
  | IElementContextmenu
  | IElementDateTimepicker
  | IElementSkeletonLoader
  | IElementNavigator
  | IElementPositioner
  | IElementTabs
  | IElementDeviceSwitch
  | IElementIndicator
  | IElementSection
  | IElementContainer
  | IElementSecret;

export type ILayoutTypeElements = Array<ILayoutTypeElement>;

export interface ILayoutElementWithChildren<Type extends string, Properties = undefined>
  extends ILayoutElement<Type, Properties> {
  children?: ILayoutTypeElements | null;
}

export type ILayoutActionClosePopup = ILayoutAction<ActionType.CLOSE_POPUP>;
export type ILayoutActionCloseWindow = ILayoutAction<ActionType.CLOSE_WINDOW>;
export type ILayoutActionScrollToTop = ILayoutAction<ActionType.SCROLL_TO_TOP>;
export type ILayoutActionConfetti = ILayoutAction<ActionType.CONFETTI>;

export type ILayoutActionType =
  | ILayoutActionRedirect
  | ILayoutActionMessage
  | ILayoutActionClosePopup
  | ILayoutActionCloseWindow
  | ILayoutActionScrollToTop
  | ILayoutActionWaitForUrl
  | ILayoutActionWaitForJob
  | ILayoutActionReload
  | ILayoutActionOpenPopup
  | ILayoutActionIframeEvent
  | ILayoutActionConfetti
  | ILayoutActionDataLayerEvent;

export enum ActionType {
  MESSAGE = 'message',
  REDIRECT = 'redirect',
  CONFIRM = 'confirm',
  RELOAD = 'reload',
  WAIT_FOR_URL = 'wait-for-url',
  WAIT_FOR_JOB = 'wait-for-job',
  SCROLL_TO_TOP = 'scroll-to-top',
  CLOSE_POPUP = 'close-popup',
  CLOSE_WINDOW = 'close-window',
  OPEN_POPUP = 'open-popup',
  IFRAME_EVENT = 'iframe-event',
  DATA_LAYER_EVENT = 'data-layer-event',
  CONFETTI = 'confetti'
}

export enum ActionMessageDisplay {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

export interface ILayoutAction<Type extends ActionType> {
  type: Type;
  bubbleToTop: boolean;
  finished?: boolean;
}

export interface ILayoutActionIframeEvent extends ILayoutAction<ActionType.IFRAME_EVENT> {
  name: string;
  data?: any;
}

export interface ILayoutActionReload extends ILayoutAction<ActionType.RELOAD> {
  topLevel?: boolean;
  forId?: string;
  fullReload: boolean;
  delay?: null | number;
}

export interface ILayoutActionOpenPopup extends ILayoutAction<ActionType.OPEN_POPUP> {
  url: string;
  headline: string;
}

export interface ILayoutActionRedirect extends ILayoutAction<ActionType.REDIRECT> {
  url: string;
  delay: number | null;
  topLevel: boolean;
}

export interface ILayoutActionWaitForUrl extends ILayoutAction<ActionType.WAIT_FOR_URL> {
  url: string;
  postData?: { [key: string]: string | number };
  onResolve?: ILayoutActionType[];
  onReject?: ILayoutActionType[];
}

export interface ILayoutActionWaitForJob extends ILayoutAction<ActionType.WAIT_FOR_JOB> {
  statusApi: string;
  onResolve?: ILayoutActionType[];
  onReject?: ILayoutActionType[];
}

export interface ILayoutActionMessage extends ILayoutAction<ActionType.MESSAGE> {
  display: ActionMessageDisplay;
  title: string;
  description: string | null;
  id?: number;
}

export interface ILayoutActionDataLayerEvent extends ILayoutAction<ActionType.DATA_LAYER_EVENT> {
  name: string;
  data?: any;
}

export interface IElementTooltip {
  text: string;
  position: string | null;
}

export interface IElementBlueprint
  extends ILayoutElementWithChildren<
    'blueprint',
    {
      template?: ILayoutTemplate | null;
      url?: string;
      actions?: Array<ILayoutActionType>;
      property_references?: {
        [key: string]: unknown;
      };
    }
  > {
  preventRender?: boolean;
  validationToken?: string;
}

export enum IElementMessageVariant {
  INFO = 'info',
  NOTICE = 'notice',
  WARNING = 'warning',
  ALERT = 'alert',
  SUCCESS = 'success'
}

export type IElementIndicator = ILayoutElement<
  'indicator',
  {
    type: string;
    noFill: boolean;
  } & HasSize &
    HasTooltip
>;

export type IElementMessage = ILayoutElementWithChildren<
  'message',
  {
    type: string | null;
    title: string | null;
    description: string | null;
    icon: boolean | null;
    display: string;
  }
>;

export enum IElementBadgeDisplay {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  STATUS = 'status'
}

export type IElementBadge = ILayoutElement<
  'badge',
  {
    display: IElementBadgeDisplay | null;
    text?: string;
    icon?: string;
  } & HasTooltip &
    HasTrigger
>;

export type IElementSkeletonLoader = ILayoutElement<
  'skeleton-loader',
  {
    type: string;
    lines: number;
    size?: string;
  }
>;

export enum AlignItems {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  BASELINE = 'baseline',
  STRETCH = 'stretch'
}

export enum AlignContent {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  STRETCH = 'stretch'
}

export enum JustifyContent {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around'
}

export interface IConditionConfirm {
  type: string;
  title?: string;
  modalTitle?: string;
  description?: string;
  button: {
    yes: string | null;
    no: string | null;
  };
  challenge: {
    enabled: boolean;
    text: string | null;
  };
}

export type Condition = IConditionConfirm;

export enum TriggerType {
  UPDATE = 'update',
  ACTION = 'action',
  POPUP = 'popup',
  COPY = 'copy',
  CLOSE_POPUP = 'close-popup',
  NAVIGATION = 'navigation',
  SET_FIELD_VALUE = 'set-field-value'
}

export interface ITrigger {
  type: TriggerType;
  action?: string | null;
  copyTarget?: string | null;
  condition?: Condition | null;
  popup?: string;
  popupHeadline?: string;
  popupOnClose?: ILayoutActionType[] | null;
  url?: string;

  // Used in set-field-value trigger
  field?: string[];
  value?: string | number;
}

export type IElementRow = ILayoutElementWithChildren<
  'row',
  {
    alignItems?: AlignItems;
    alignContent?: AlignContent;
    justifyContent?: JustifyContent;
    itemsDisplay?: string;
  } & HasGapSize
>;

export type IElementContainer = ILayoutElementWithChildren<
  'container',
  {
    alignItems?: AlignItems;
    height?: string;
  } & HasGapSize
>;

export type IElementColumn = ILayoutElementWithChildren<
  'column',
  {
    alignItems?: AlignItems;
    justifyContent?: JustifyContent;
  } & HasSize
>;

export interface IElementDictionaryItems {
  [key: string]: string;
}

export type IElementDevicePreview = ILayoutElement<
  'device-preview',
  {
    desktopUrl?: string;
    mobileUrl?: string;
  }
>;

export type IElementDictionary = ILayoutElement<
  'dictionary',
  {
    items?: IElementDictionaryItems;
  }
>;

export interface IElementTabsItem {
  id: string;
  text?: string;
  icon?: string;
  content: ILayoutTypeElement[] | null;
  disabled: boolean;
  error?: boolean;
}

export type IElementTabs = ILayoutElementForm<
  'tabs',
  {
    tabs: IElementTabsItem[];
    justifyContent?: JustifyContent;
  } & HasDisabled &
    HasHelp &
    HasLabel &
    HasTrigger &
    HasSize,
  string
>;

export type IElementSettingsNavigation = ILayoutElementForm<
  'settings-navigation',
  {
    items: Omit<IElementTabsItem, 'icon'>[];
  } & HasDisabled &
    HasTrigger,
  string
>;

export interface IElementNavigatorItem {
  id: string;
  trigger: ILayoutTypeElement[];
  content: ILayoutTypeElement[];
}

export type IElementNavigator = ILayoutElementForm<
  'navigator',
  {
    items: IElementNavigatorItem[];
  } & HasLabel,
  string
>;

export type IElementEmbed = ILayoutElement<
  'embed',
  {
    url: string;
  }
>;

export interface IElementStepGuideStep {
  id: string;
  title: string;
  element: ILayoutTypeElement;
}

export type IElementStepGuide = ILayoutElementForm<
  'step-guide',
  {
    steps: IElementStepGuideStep[];
    size?: string;
  } & HasTrigger,
  string
>;

export type IElementAccordion = ILayoutElementWithChildren<
  'accordion',
  {
    title?: string;
    description?: string;
    initiallyOpen?: boolean;
    number?: string;
    icon?: string;
    level: string;
  } & HasSize
>;

export type IElementSection = ILayoutElementWithChildren<
  'section',
  {
    title?: string;
    description?: string;
    heading?: number;
  } & HasDisabled &
    HasGapSize
> &
  HasToolbar;

export type IElementLink = ILayoutElementWithChildren<
  'link',
  {
    href?: string;
    target?: string;
    download?: string;
  }
>;

export type IElementButton = ILayoutElementForm<
  'button',
  {
    type?: string;
    color?: string;
    text?: string;
    href?: string;
    target?: string;
    icon?: string;
    submit?: boolean;
    autoClick?: boolean;
  } & HasTrigger &
    HasDisabled &
    HasTooltip &
    HasSize,
  string
>;

export type IElementSize = ILayoutElementForm<
  'size',
  {
    units: string[];
    max?: number;
  } & HasHelp &
    HasTrigger &
    HasLabel &
    HasPlaceholder &
    HasDisabled &
    HasReadonly &
    HasSize,
  string | number
>;

export type IElementRecaptcha = ILayoutElementForm<
  'recaptcha',
  {
    siteKey: string;
  } & HasLabel &
    HasTrigger &
    HasHelp,
  string
>;

export type IElementPhoneNumber = ILayoutElementForm<
  'phone-number',
  {
    phoneCodes: string;
  } & HasReadonly &
    HasLabel &
    HasPlaceholder &
    HasDisabled &
    HasHelp &
    HasSize,
  string | number
>;

export type IElementTextField = ILayoutElementForm<
  'text-field',
  {
    autofocus?: boolean;
    maxLength?: number;
    type?: string;
    append?: string;
    prepend?: string;
    min?: number;
    max?: number;
    mask?: string;
    disableTranslation?: boolean;
  } & HasTrigger &
    HasReadonly &
    HasLabel &
    HasPlaceholder &
    HasDisabled &
    HasHelp &
    HasSize &
    HasAiAssistant,
  string | number
>;

export type IElementSlider = ILayoutElementForm<
  'slider',
  {
    min: number;
    max: number;
    step: number;
    ticks: boolean;
  } & HasLabel &
    HasTrigger &
    HasDisabled &
    HasPlaceholder &
    HasReadonly &
    HasHelp &
    HasSize,
  number
>;

export type IElementDeviceSwitch = ILayoutElementForm<
  'device-switch',
  {
    tabs: IElementTabsItem[];
  } & HasSize,
  unknown
>;

export type IElementDatepicker = ILayoutElementForm<
  'datepicker',
  {
    max?: string;
    min?: string;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled &
    HasSize,
  string | string[]
>;

export type IElementTimepicker = ILayoutElementForm<
  'timepicker',
  {
    seconds?: boolean;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled,
  string
>;

export type IElementDateTimepicker = ILayoutElementForm<
  'datepicker',
  {
    maxDate?: string;
    minDate?: string;
    input?: string;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled,
  string
>;

export type IElementDivider = ILayoutElement<'divider', object>;

export type IElementTextarea = ILayoutElementForm<
  'textarea',
  {
    maxLength?: number;
    disableTranslation?: boolean;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled &
    HasReadonly &
    HasPlaceholder &
    HasSize &
    HasAiAssistant,
  string
>;

export interface IElementPositionerValue {
  id: number | string;
  x: number;
  y: number;
  w: number;
  h: number;
  label?: string;
  style?: {
    [key: string]: string;
  };
}

export type IElementPositioner = ILayoutElementForm<
  'positioner',
  {
    image?: string;
    borderRadius?: number;
    title?: string;
    canvas: boolean;
    canvasWidth?: number;
    canvasHeight?: number;
  } & HasLabel &
    HasHelp &
    HasDisabled,
  IElementPositionerValue[]
>;

export type IElementMedia = ILayoutElementForm<
  'media',
  {
    selector?: IElementMediaSelector;
    target?: string;
    preview: boolean;
    cursorSelect?: {
      x: string[] | null;
      y: string[] | null;
    };
    tmp: boolean;
    altText?: IElementTextField;
    multiple?: boolean;
  } & HasLabel &
    HasHelp &
    HasPlaceholder &
    HasTrigger &
    HasDisabled &
    HasReadonly &
    HasSize,
  string
>;

export enum ElementMediaSelectorType {
  FILE = 'file',
  FOLDER = 'folder'
}

export interface IElementMediaSelector {
  type?: ElementMediaSelectorType;
  extensions?: string[];
  validations?: {
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
    minWidth?: number;
    aspectRatio?: string;
  };
}

export type IElementHidden = ILayoutElementForm<'hidden', object, string | number>;

export interface IElementTableRow {
  columns: IElementTableRowColumns;
  id?: number;
}

export type IElementTableValue = {
  search: string;
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortByDirection: string;
  bulk?: string[] | number[] | null;
};

export type IElementTable = ILayoutElementForm<
  'table',
  {
    header?: IElementTableHeader[];
    body?: IElementTableRow[];
    searchable?: boolean;
    searchablePlaceholder?: string;
    sortable?: boolean;
    reorder?: boolean;
    itemKey?: string;
    paginating?: boolean;
    actions?: boolean;
    totalRows?: number;
    clientSide: boolean;
    hideHeader?: boolean;
    bulkSelect?: boolean;
    overflow?: boolean;
  } & HasAsyncRender,
  IElementTableValue
> &
  HasToolbar;

export interface IElementTableHeader {
  searchable: boolean;
  sortable: boolean;
  hasPadding?: boolean;
  text?: string;
  align?: string;
  value?: string;
  width?: string;
}

export interface IElementTableRowColumn {
  column: string | number | ILayoutTypeElement[];
}

export interface IElementTableRowColumns {
  [key: string]: IElementTableRowColumn;
}

export type IElementColorpicker = ILayoutElementForm<
  'colorpicker',
  {
    colorScheme?: string[];
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled,
  string
>;

export type IElementSwitch = ILayoutElementForm<
  'switch',
  object & HasLabel & HasHelp & HasTrigger & HasDisabled,
  boolean
>;

export type IElementCheckbox = ILayoutElementForm<
  'checkbox',
  object & HasLabel & HasHelp & HasTrigger & HasDisabled,
  boolean
>;

export type IElementCheckboxes = ILayoutElementForm<
  'checkboxes',
  {
    options?: IElementCheckboxesOption[];
  } & HasLabel &
    HasHelp &
    HasDisabledArray<string[]> &
    HasTrigger,
  string[]
>;

export interface IElementCheckboxesOption {
  value?: string;
  text?: string;
}

export type IElementRadio = ILayoutElementForm<
  'radio',
  {
    isGrouped?: boolean;
    display: string;
    options?: IElementRadioOption[];
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled,
  number | string
>;

export interface IElementRadioOptions {
  value?: string | number;
  text?: string | number;
  elements?: ILayoutTypeElement[];
}

export interface IElementRadioOption {
  group?: string;
  value?: string | number;
  text?: string | number;
  options: IElementRadioOptions[];
}

export enum ChartVariant {
  AREA = 'area',
  DOUGHNUT = 'doughnut',
  BAR = 'bar',
  PIE = 'pie'
}

export interface IElementChartData {
  [key: number]: {
    [key: string]: number;
  };
}

export interface IElementChartColors {
  [key: string]: string;
}

export interface IElementChartLabels {
  [key: string]: string;
}

export type IElementChart = ILayoutElement<
  'chart',
  {
    variant: ChartVariant;
    data?: IElementChartData | number[];
    colors?: IElementChartColors | string[];
    labels?: IElementChartLabels | string[];
    hide?: boolean;
    units?: string;
  }
>;

export type IElementParagraph = ILayoutElement<
  'paragraph',
  {
    tooltip?: IElementTooltip;
    text?: string;
    typography?:
      | 'text-body-strong'
      | 'text-body-small'
      | 'text-body-small-strong'
      | 'text-body-strong-underlined'
      | 'text-body-large'
      | 'text-body-large-strong';
  }
>;

export type IElementHeadline = ILayoutElement<
  'headline',
  {
    text?: string;
    heading: number;
  } & HasTooltip
>;

export type IElementSelect = ILayoutElementForm<
  'select',
  {
    options?: IElementSelectOption[];
    searchable: boolean;
    multiple?: boolean;
    icon?: string;
    typeahead?: {
      url: string;
    };
  } & HasLabel &
    HasHelp &
    HasSize &
    HasPlaceholder &
    HasDisabled &
    HasTrigger,
  number | string | Array<number | string>
>;

export type IElementFontSelector = ILayoutElementForm<
  'font-selector',
  object & HasLabel & HasHelp & HasSize & HasPlaceholder & HasDisabled & HasTrigger,
  string
>;

export interface IElementSelectOption {
  text: string;
  value: string;
  header?: string;
}

export type IElementCard = ILayoutElementForm<
  'card',
  {
    cards: IElementCardOption[];
    justifyContent?: string;
    multiple: boolean;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled,
  string | string[]
>;

export interface IElementCardOption {
  value?: string | number;
  headline?: string;
  description?: string;
  icon?: string;
  image?: string;
  imageRotation?: number;
  imageWidth?: number;
  imageOverlay?: string;
  disabled?: boolean;
  error?: boolean;
}

export type IElementTagsInput = ILayoutElementForm<
  'tags-input',
  {
    useArray: boolean;
    hide?: boolean;
  } & HasLabel &
    HasHelp &
    HasDisabled &
    HasSize &
    HasTrigger,
  string | string[]
>;

export type IElementCodeEditor = ILayoutElementForm<
  'code-editor',
  {
    language: string | null;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasReadonly &
    HasDisabled,
  string
>;

export interface IElementTextEditorPageOption {
  value: string | number;
  text: string;
}

export type IElementTextEditor = ILayoutElementForm<
  'textEditor',
  {
    enterTag: string;
    toolbar?: string[];
    sections?: IElementTextEditorPageOption[];
    popovers?: IElementTextEditorPageOption[];
    disableTranslation?: boolean;
  } & HasLabel &
    HasHelp &
    HasTrigger &
    HasDisabled &
    HasReadonly &
    HasPlaceholder &
    HasSize &
    HasAiAssistant,
  string
>;

export type IElementIcon = ILayoutElement<
  'icon',
  {
    icon: null | string;
  } & HasTooltip &
    HasSize
>;

export type IElementSvgObject = ILayoutElement<
  'svg-object',
  {
    src: string;
    width: number;
    height: number;
  }
>;

export type IElementImage = ILayoutElement<
  'image',
  {
    src?: string;
    width?: number;
    height?: number;
    maxWidth?: number;
    maxHeight?: number;
    rotation?: number;
    overlayImage?: string;
  } & HasTooltip
>;

export interface IElementGoogleMapMarker {
  position: {
    lng: number;
    lat: number;
  };
  icon: string | null;
  html: string | null;
}

export type IElementGoogleMap = ILayoutElement<
  'google-map',
  {
    markers?: IElementGoogleMapMarker[];
    zoom: number;
    centerLng: number;
    centerLat: number;
  }
>;

export type IElementTransitionGroup = ILayoutElementWithChildren<
  'transition-group',
  {
    minHeight?: number;
    animation: string;
    duration: number;
  }
>;

export type IElementContextmenu = ILayoutElementWithChildren<
  'contextmenu',
  {
    activator: ILayoutTypeElement[];
    close: boolean;
  }
>;

export type IElementVerificationCode = ILayoutElementForm<
  'verification-code',
  {
    autofocus?: boolean;
    inputNumber?: number;
  } & HasLabel &
    HasHelp &
    HasDisabled &
    HasTrigger &
    HasPlaceholder,
  number
>;

export type IElementSecret = ILayoutElementForm<
  'secret',
  {
    secretValue?: string;
  } & HasLabel &
    HasHelp &
    HasDisabled &
    HasReadonly &
    HasSize &
    HasTrigger,
  string
>;
