<template>
  <p-page v-if="workSpaceStore.workspace" send-analytics>
    <p-nav-tabs :headline="navHeadline" :items="tabs" />

    <p-section>
      <p-modal-confirm
        :show.prop="true"
        v-if="campaignDeleteConfirm"
        :confirm-text="campaignDeleteConfirm.name"
        description="Deletion of this campaign cannot be undone!"
        :title="confirmModalTitle"
        :callback.prop="deleteCampaign"
        @close-request="campaignDeleteConfirm = null"
      />

      <p-modal-confirm
        :show.prop="true"
        v-if="campaignPauseConfirm"
        :callback.prop="pauseCampaign"
        modal-title="Pause"
        :title="confirmModalTitle"
        description=""
        button-yes="Pause"
        button-no="Cancel"
        @close-request="campaignPauseConfirm = null"
      />

      <p-table
        :pagination="!isPopupSorting"
        :searchable="true"
        :header="[
          { id: 'name', label: 'Name', width: '240px', verticalAlign: 'top' },
          { id: 'id', label: 'ID', width: '140px', verticalAlign: 'top' },
          { id: 'creator', label: 'Creator', width: '140px', verticalAlign: 'top' },
          { id: 'activity', label: 'Activity', width: '140px', verticalAlign: 'top' },
          { id: 'creation', label: 'Creation', width: '140px', nowrap: true, verticalAlign: 'top' },
          { id: 'actions', verticalAlign: 'top' }
        ]"
        :body="campaignsBody"
        :skeleton-loader="waitingForCampaigns || waitingForCampaignTypes"
        :skeleton-loader-rows="tableState.itemsPerPage"
        :skeleton-loader-text-lines="2"
        :total-items="campaigns?.meta.total ?? 0"
        :reorder="isPopupSorting && !waitingForSort"
        :loading="waitingForSort"
        v-model="tableState"
        @sort="onSort"
      >
        <template #toolbar>
          <p-form-select
            v-model="selectedUser"
            searchable
            size="small"
            label="User"
            :options="userOptions"
            :disabled="waitingForCampaigns || waitingForCampaignTypes || waitingForSort"
          />

          <p-form-select
            v-model="selectedStatus"
            size="small"
            label="Status"
            :options="statusOptions"
            :disabled="waitingForCampaigns || waitingForCampaignTypes || waitingForSort"
          />

          <p-form-select
            v-if="!hasTypePreselected"
            v-model="selectedGameType"
            searchable
            size="small"
            label="Game type"
            :options="campaignTypeOptions"
            :disabled="waitingForCampaigns || waitingForCampaignTypes || waitingForSort"
          />

          <p-form-select
            v-if="hasTags"
            v-model="selectedTag"
            searchable
            size="small"
            label="Tags"
            :options="tagsOptions"
            :disabled="waitingForCampaigns || waitingForCampaignTypes || waitingForSort"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template #bodyCell="{ row, column, header }">
          <!-- Name -->
          <template v-if="header.id === 'name'">
            <p-tooltip :text="column" v-if="column && column.length > 10">
              <div class="campaign-name">{{ column }}</div>
            </p-tooltip>
            <div class="campaign-name" v-else>{{ column }}</div>

            <p-row gap-size="small">
              <p-badge v-if="row.device_type" type="info" :text="row.device_type" />
              <p-badge v-if="row.template?.content" type="info" text="Content" />
              <p-badge v-if="row.template?.layout" type="info" text="Layout" />
            </p-row>
          </template>

          <!-- ID -->
          <template v-else-if="header.id === 'id'">
            <p-paragraph>{{ column }}</p-paragraph>

            <span class="decorative-text">
              <p-paragraph typography="component-text-small" v-if="row.typeName">{{ row.typeName }}</p-paragraph>
            </span>
          </template>

          <!-- Activity -->
          <template v-else-if="header.id === 'activity' && row.permissions.statistic">
            <p-paragraph>
              <p-link :href="`/campaign/${row.type}/${row.id}/activity/statistics`">{{ row.sessions }}</p-link>
              sessions
            </p-paragraph>

            <span class="decorative-text">
              <p-paragraph typography="component-text-small">{{ row.registrations }} registrations</p-paragraph>
            </span>
          </template>

          <!-- Creator -->
          <template v-else-if="header.id === 'creator'">
            <p-tooltip :text="column" v-if="column && column.length > 10">
              <div class="creator-name">{{ column }}</div>
            </p-tooltip>
            <div class="creator-name" v-else>{{ column }}</div>
          </template>

          <!-- Created on -->
          <template v-else-if="header.id === 'creation'">
            <div class="campaign-creation">
              <p-paragraph>{{ row.created_on }}</p-paragraph>

              <p-badge v-if="row.state === 'paused'" type="negative" text="Paused" />
              <p-badge v-else-if="row.state === 'live' && row.active_always" type="positive" text="Always on" />
              <p-badge
                v-else-if="row.state === 'live' && !row.active_always"
                type="positive"
                :text="`Live, ${row.days_left_live} days left`"
              />
              <p-badge v-else-if="row.state === 'expired'" type="negative" text="Expired: Reg. data deleted" />
              <p-badge
                v-else-if="row.state === 'in_expire_cycle'"
                type="warning"
                :text="`Reg. data has ${row.days_until_retention} days left`"
              />
              <p-badge v-else text="Unpublished" />
            </div>
          </template>

          <!-- Actions -->
          <template v-else-if="header.id === 'actions'">
            <p-tooltip text="View Campaign" position="bottom">
              <p-link :href="row.demo_url" target="_blank">
                <p-button color-type="tertiary" icon="eye" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip text="Edit" position="bottom">
              <p-link :href="`/campaign/${row.type}/${row.id}/modify`">
                <p-button color-type="tertiary" icon="edit" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip v-if="row.permissions.copy" text="Copy" position="bottom">
              <p-link :href="`/campaign/copy/${row.id}`">
                <p-button color-type="tertiary" icon="copy" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip text="Statistics" position="bottom">
              <p-link :href="`/campaign/${row.type}/${row.id}/activity/statistics`">
                <p-button color-type="tertiary" icon="bar-chart-2" size="medium" />
              </p-link>
            </p-tooltip>

            <p-context-menu>
              <template #activator>
                <p-button color-type="tertiary" icon="more-vertical" size="medium" />
              </template>

              <p-container>
                <p-button
                  v-if="row.state === 'paused' && row.permissions.pause"
                  color-type="tertiary"
                  size="medium"
                  @click="resumeCampaign(row)"
                >
                  Resume</p-button
                >

                <p-button
                  v-if="row.state === 'live' && row.permissions.pause"
                  color-type="tertiary"
                  size="medium"
                  @click="campaignPauseConfirm = row"
                >
                  Pause</p-button
                >

                <p-button
                  v-if="row.state !== 'live' && !row.archived"
                  color-type="tertiary"
                  size="medium"
                  @click="archiveCampaign(row)"
                >
                  Archive</p-button
                >

                <p-button
                  v-if="row.state !== 'live' && row.archived"
                  color-type="tertiary"
                  size="medium"
                  @click="removeArchiveCampaign(row)"
                  >Restore from archive</p-button
                >

                <p-button v-if="!row.bookmarked" color-type="tertiary" size="medium" @click="bookmarkCampaign(row)"
                  >Bookmark</p-button
                >
                <p-button v-else color-type="tertiary" size="medium" @click="removeBookmarkCampaign(row)"
                  >Remove bookmark</p-button
                >

                <p-link v-if="row.state === 'live'" :href="row.live_url" target="_blank">
                  <p-button color-type="tertiary" size="medium">Live URL</p-button>
                </p-link>

                <p-button
                  v-if="row.features.objectives"
                  size="medium"
                  color-type="tertiary"
                  @click="campaignObjectivesPopup = row"
                  >Campaign objectives</p-button
                >

                <p-button
                  v-if="row.permissions.delete"
                  color-type="tertiary"
                  size="medium"
                  @click="campaignDeleteConfirm = row"
                >
                  Delete</p-button
                >
              </p-container>
            </p-context-menu>
          </template>
          <!-- Default -->
          <template v-else>
            {{ column }}
          </template>
        </template>
      </p-table>

      <layout-element-popup
        v-if="campaignObjectivesPopup"
        :url="`/campaign/${campaignObjectivesPopup.type}/${campaignObjectivesPopup.id}/publishing/campaign-objectives`"
        headline="Campaign objectives"
        @closePopup="campaignObjectivesPopup = null"
        @actionClosePopup="onActionClosePopup()"
      />
    </p-section>
  </p-page>
</template>

<script lang="ts">
import { AppRequest } from '@/app_request';
import { TableBody, TableState } from '@/components/ui/Table.vue';
import { TabsNavItem } from '@/components/ui/custom/nav-tabs/types';
import { DropdownOption } from '@/components/ui/form/types';
import { CampaignResource, CampaignTypeResource } from '@/types/api/campaign';
import { PaginatedResult } from '@/types/api/generic';
import { TagResource } from '@/types/api/tag';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import { debounce } from 'lodash-decorators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { useWorkspaceStore } from '@/store/workspaceStore';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';
import { useUsersStore } from '@/store/usersStore';

interface CampaignTypeResourceMap {
  [key: string]: CampaignTypeResource;
}

@Component({
  components: { LayoutElementPopup }
})
export default class extends Vue {
  public workSpaceStore = useWorkspaceStore();

  public campaignDeleteConfirm: CampaignResource | null = null;
  public campaignPauseConfirm: CampaignResource | null = null;

  public waitingForCampaigns = false;
  public campaigns: PaginatedResult<CampaignResource> | null = null;

  public waitingForCampaignTypes = true;
  public campaignTypes: CampaignTypeResource[] = [];

  public waitingForSort = false;

  public campaignObjectivesPopup: CampaignResource | null = null;

  public tags: TagResource[] = [];

  public selectedUser = '';
  public selectedGameType = '';
  public selectedStatus = '';
  public selectedTag = '';

  public statusOptions: DropdownOption[] = [
    {
      value: '',
      text: 'All'
    },
    {
      value: 'active',
      text: 'Active'
    },
    {
      value: 'inactive',
      text: 'Inactive'
    },
    {
      value: 'paused',
      text: 'Paused'
    },
    {
      value: 'templates',
      text: 'Templates'
    },
    {
      value: 'bookmarked',
      text: 'Bookmarked'
    },
    {
      value: 'archived',
      text: 'Archived'
    }
  ];

  public tableState: TableState = {
    sorting: {
      column: '',
      direction: 'none'
    },
    searchTerm: '',
    itemsPerPage: 10,
    currentPage: 1,
    bulkSelections: []
  };

  public get confirmModalTitle(): string {
    if (this.campaignPauseConfirm) {
      return `Are you sure you want to pause "${this.campaignPauseConfirm.name}"?`;
    } else if (this.campaignDeleteConfirm) {
      return `Are you sure you want to delete "${this.campaignDeleteConfirm.name}"?`;
    } else {
      return '';
    }
  }

  public mounted() {
    this.selectedGameType = this.$route.query.type ? String(this.$route.query.type) : '';

    this.loadCampaigns();
    this.loadCampaignTypes();
    this.loadTags();
  }

  @Watch('$route.query.type')
  public onRouteTypeChange() {
    this.selectedGameType = this.$route.query.type ? String(this.$route.query.type) : '';
  }

  public onActionClosePopup() {
    setTimeout(() => {
      this.campaignObjectivesPopup = null;
    }, 1000);
  }

  @Watch('tableState.searchTerm')
  @debounce(1500)
  public onSearchTermChange() {
    this.loadCampaigns(true);

    if (this.tableState.currentPage > 1) {
      this.tableState.currentPage = 1;
    }
  }

  @Watch('selectedUser')
  @Watch('selectedStatus')
  @Watch('selectedGameType')
  @Watch('selectedTag')
  public onFilterChange() {
    this.loadCampaigns(true);

    if (this.tableState.currentPage > 1) {
      this.tableState.currentPage = 1;
    }
  }

  @Watch('$route.query.folder_id')
  @Watch('tableState.itemsPerPage')
  @Watch('tableState.currentPage')
  public onTableFilterChange() {
    this.loadCampaigns(true);
  }

  public async onSort(oldIndex: number, newIndex: number) {
    if (this.campaigns?.data) {
      this.waitingForSort = true;

      const movedItem = this.campaigns.data.splice(oldIndex, 1)[0];
      this.campaigns.data.splice(newIndex, 0, movedItem);

      for (const campaign of this.campaigns.data) {
        await AppRequest.patch(`/api/v1/campaign/${campaign.uuid}`, {
          weight: this.campaigns.data.indexOf(campaign)
        });
      }

      this.waitingForSort = false;
    }
  }

  public async bookmarkCampaign(campaign: CampaignResource) {
    this.waitingForCampaigns = true;
    await AppRequest.post(`/api/v1/campaign/${campaign.uuid}/bookmark`);
    await this.loadCampaigns();
  }

  public async removeBookmarkCampaign(campaign: CampaignResource) {
    this.waitingForCampaigns = true;
    await AppRequest.post(`/api/v1/campaign/${campaign.uuid}/remove-bookmark`);
    await this.loadCampaigns();
  }

  public async archiveCampaign(campaign: CampaignResource) {
    this.waitingForCampaigns = true;
    await AppRequest.post(`/api/v1/campaign/${campaign.uuid}/archive`);
    await this.loadCampaigns();
  }

  public async removeArchiveCampaign(campaign: CampaignResource) {
    this.waitingForCampaigns = true;
    await AppRequest.delete(`/api/v1/campaign/${campaign.uuid}/archive`);
    await this.loadCampaigns();
  }

  public async resumeCampaign(campaign: CampaignResource) {
    if (!campaign.permissions.pause) {
      return;
    }

    this.waitingForCampaigns = true;
    await AppRequest.delete(`/api/v1/campaign/${campaign.uuid}/pause`);
    await this.loadCampaigns();
  }

  public async pauseCampaign() {
    if (!this.campaignPauseConfirm || !this.campaignPauseConfirm.permissions.pause) {
      return;
    }

    await AppRequest.post(`/api/v1/campaign/${this.campaignPauseConfirm.uuid}/pause`);
    await this.loadCampaigns(false);
  }

  public async deleteCampaign() {
    if (!this.campaignDeleteConfirm || !this.campaignDeleteConfirm.permissions.delete) {
      return;
    }

    await AppRequest.delete(`/api/v1/campaign/${this.campaignDeleteConfirm.uuid}`);
    await this.loadCampaigns(false);
  }

  public get campaignsBody(): TableBody[] {
    return (
      this.campaigns?.data.map((campaign) => {
        let daysLeftLive: number | undefined = undefined;
        let daysUntilRetention: number | undefined = undefined;

        if (campaign.state === 'live') {
          const activeTo = new Date(campaign.active_to);
          const currentDate = new Date();
          daysLeftLive = differenceInDays(activeTo, currentDate);
        } else if (campaign.state === 'in_expire_cycle') {
          let retentionDays = 0;

          switch (campaign.retention) {
            case '30_days_after_expire':
              retentionDays = 30;
              break;

            case '60_days_after_expire':
              retentionDays = 60;
              break;

            case '90_days_after_expire':
              retentionDays = 90;
              break;
          }

          const expireCycleEnd = addDays(new Date(campaign.active_to), retentionDays);
          const currentDate = new Date();
          daysUntilRetention = differenceInDays(expireCycleEnd, currentDate);
        }

        return {
          ...campaign,
          typeName:
            Object.keys(this.campaignTypes).length > 0
              ? this.campaignTypesMap[`${campaign.type}`]?.name ?? 'N/A'
              : undefined,
          creator: campaign.created_by.name,
          days_left_live: daysLeftLive,
          days_until_retention: daysUntilRetention,
          created_on: format(new Date(campaign.created_on), 'dd-MM-yyyy')
        };
      }) ?? []
    );
  }

  public get userOptions(): DropdownOption[] {
    const usersStore = useUsersStore();

    return [
      {
        value: '',
        text: 'All'
      },
      ...usersStore.users.map<DropdownOption>((user) => {
        return {
          value: String(user.id),
          text: user.name
        };
      })
    ];
  }

  public get hasTags() {
    return this.tags.length > 0;
  }

  public get tagsOptions(): DropdownOption[] {
    return [
      {
        value: '',
        text: 'All'
      },
      ...this.tags.map<DropdownOption>((tag) => {
        return {
          value: String(tag.id),
          text: tag.name
        };
      })
    ];
  }

  public get campaignTypeOptions(): DropdownOption[] {
    return [
      {
        value: '',
        text: 'All'
      },
      ...this.campaignTypes.map<DropdownOption>((type) => {
        return {
          value: type.alias,
          text: type.name
        };
      })
    ];
  }

  public get campaignTypesMap(): CampaignTypeResourceMap {
    const campaignTypes: CampaignTypeResourceMap = {};

    this.campaignTypes.forEach((campaignType) => {
      campaignTypes[campaignType.alias] = campaignType;
    });

    return campaignTypes;
  }

  public get hasTypePreselected() {
    return !!this.$route.query.type;
  }

  public get selectedGameTypeName() {
    return this.campaignTypesMap[this.selectedGameType ?? '']?.name ?? 'Loading...';
  }

  public get tabs(): TabsNavItem[] {
    return [
      {
        text: 'Campaigns',
        href: '/campaigns',
        active: !this.$route.query.folder_id
      },
      {
        text: 'Shared folders',
        href: '/campaigns/shared-folders',
        active: !!this.$route.query.folder_id
      }
    ];
  }

  public get navHeadline() {
    if (this.$route.query.folder_id) {
      return 'Shared folders';
    }

    return this.hasTypePreselected ? this.selectedGameTypeName : 'Campaigns';
  }

  private async loadTags() {
    this.tags = (await AppRequest.get<{ data: TagResource[] }>('/api/v1/workspace/tags')).data.data;
  }

  private async loadCampaigns(shouldWait = true) {
    if (shouldWait) {
      this.waitingForCampaigns = true;
    }

    const response = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          sort: this.isPopupSorting ? ['weight:asc'] : ['created_on:desc', 'name:asc'],
          page: this.isPopupSorting ? 1 : this.tableState.currentPage,
          search: this.tableState.searchTerm,
          per_page: this.isPopupSorting ? 100 : this.tableState.itemsPerPage,
          with: ['sessions', 'registrations'],
          ...(this.selectedGameType && { type: [this.selectedGameType] }),
          ...(this.selectedUser && { created_by: [this.selectedUser] }),
          ...(this.selectedStatus && { status: this.selectedStatus }),
          ...(this.$route.query.folder_id && { folder: this.$route.query.folder_id }),
          ...(this.selectedTag && { tag: this.selectedTag })
        }
      })
    ).data;

    this.campaigns = response;

    if (shouldWait) {
      this.waitingForCampaigns = false;
    }
  }

  private async loadCampaignTypes() {
    this.waitingForCampaignTypes = true;

    this.campaignTypes = (await AppRequest.get<{ data: CampaignTypeResource[] }>('/api/v1/campaign-type')).data.data;

    this.waitingForCampaignTypes = false;
  }

  private get isPopup() {
    return ['popup', 'popupv2'].includes(this.selectedGameType);
  }

  public get isPopupSorting() {
    return (
      this.isPopup &&
      !this.tableState.searchTerm &&
      !this.selectedUser &&
      !this.selectedTag &&
      this.selectedStatus === 'active' &&
      !this.$route.query.folder_id
    );
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/mixins/typography';

::v-deep {
  .campaign-name {
    @include component-text-strong;

    color: var(--text-color-headline);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.creator-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.campaign-creation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-extra-small);
}

.decorative-text {
  display: inline-flex;
  align-items: center;
  height: 26px;

  --text-color-default: var(--text-color-help);
}
</style>
