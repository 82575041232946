<template>
  <div
    class="table"
    :class="{ 'table--skeleton': skeletonLoader, 'table--reorder': reorder }"
    :style="{ 'overflow-x': overflow ? 'auto' : undefined }"
  >
    <div
      class="table__header"
      v-if="
        searchable ||
        ($slots.toolbar && $slots.toolbar.length > 0) ||
        ($scopedSlots.toolbar && $scopedSlots.toolbar(null))
      "
    >
      <div class="table__search" v-if="searchable">
        <p-search
          :modelValue="modelValue.searchTerm"
          @update:modelValue="handleSearch"
          :placeholder="searchablePlaceholder ?? 'Search'"
          :autofocus="searchableAutofocus"
          :readonly="skeletonLoader || loading"
        />
      </div>

      <div
        class="table__toolbar"
        v-if="($slots.toolbar && $slots.toolbar.length > 0) || ($scopedSlots.toolbar && $scopedSlots.toolbar(null))"
      >
        <slot name="toolbar" />
      </div>
    </div>

    <table class="table__layout">
      <thead v-if="!hideHeader">
        <tr>
          <th v-if="hasBulkSelections" class="table__layout-button">
            <div class="table__layout-checkbox">
              <p-checkbox :disabled="skeletonLoader || loading" v-model="toggleAllBulkSelections" />
            </div>
          </th>

          <th
            v-for="(item, index) in header"
            :key="index"
            @click="handleSorting(item)"
            :style="{
              width: item.width || undefined,
              maxWidth: item.width || undefined,
              minWidth: item.width || undefined,
              cursor: item.sortable ? 'pointer' : undefined
            }"
          >
            <span>
              {{ item.label }}
              <p-icon v-if="item.sortable" size="small" :icon="sortIcon[item.id]" />
            </span>
            <p-help-text v-if="item.helpText">{{ item.helpText }}</p-help-text>
          </th>
        </tr>
      </thead>

      <tbody v-if="skeletonLoader">
        <tr v-for="rowIndex in skeletonLoaderRows ? skeletonLoaderRows : modelValue.itemsPerPage" :key="rowIndex">
          <td
            v-if="hasBulkSelections"
            :style="header && header[0] && header[0].verticalAlign ? { verticalAlign: header[0].verticalAlign } : {}"
          >
            <p-checkbox :disabled="true" :modelValue="false" />
          </td>
          <template v-if="header">
            <td
              v-for="(item, index) in header"
              :key="index"
              :style="columnStyle[item.id]"
              :class="{ 'table__layout-actions': item.id === 'actions' }"
            >
              <div class="table__layout-skeleton" v-if="item.id === 'actions'">
                <p-skeleton-loader size="small">
                  <p-button color-type="tertiary" icon="eye" size="small" />
                </p-skeleton-loader>
              </div>

              <div class="table__layout-skeleton" v-else>
                <p-skeleton-loader v-for="i in skeletonLoaderTextLines" :key="i" />
              </div>
            </td>
          </template>
          <template v-else>
            <td>
              <div class="table__layout-skeleton">
                <p-skeleton-loader v-for="i in skeletonLoaderTextLines" :key="i" />
              </div>
            </td>
          </template>
        </tr>
      </tbody>

      <transition-group
        v-else-if="body.length > 0 && reorder"
        :list="body"
        is="draggable"
        tag="tbody"
        @start="startDragging"
        @end="endDragging"
        animation="300"
      >
        <tr v-for="row in body" :key="row.id">
          <td
            v-if="hasBulkSelections"
            :style="header && header[0] && header[0].verticalAlign ? { verticalAlign: header[0].verticalAlign } : {}"
          >
            <p-checkbox
              :modelValue="modelValue.bulkSelections.includes(String(row.id))"
              @update:modelValue="handleBulkSelection(String(row.id))"
            />
          </td>

          <template v-if="header">
            <td
              v-for="(item, index) in header"
              :key="index"
              :style="columnStyle[item.id]"
              :class="{ 'table__layout-actions': item.id === 'actions', 'table__layout-no-padding': !item.hasPadding }"
            >
              <div v-if="item.id === 'actions'" class="table__layout-actions-content">
                <slot v-bind:row="row" v-bind:header="item" v-bind:column="row[item.id]" name="bodyCell">
                  {{ row[item.id] }}
                </slot>
              </div>

              <slot v-else v-bind:row="row" v-bind:header="item" v-bind:column="row[item.id]" name="bodyCell">
                <template v-if="!Array.isArray(row[item.id])">
                  {{ row[item.id] }}
                </template>
              </slot>
            </td>
          </template>
          <template v-else>
            <td v-for="(item, index) in Object.keys(row)" :key="index">
              <slot v-bind:row="row" v-bind:header="undefined" v-bind:column="row[item]" name="bodyCell">
                {{ row[item] }}
              </slot>
            </td>
          </template>
        </tr>
      </transition-group>

      <tbody v-else-if="body.length > 0 && !reorder">
        <tr v-for="(row, rowIndex) in body" :key="rowIndex">
          <td
            v-if="hasBulkSelections"
            :style="header && header[0] && header[0].verticalAlign ? { verticalAlign: header[0].verticalAlign } : {}"
          >
            <p-checkbox
              :modelValue="modelValue.bulkSelections.includes(String(row.id))"
              @update:modelValue="handleBulkSelection(String(row.id))"
            />
          </td>

          <template v-if="header">
            <td
              v-for="(item, index) in header"
              :key="index"
              :style="columnStyle[item.id]"
              :class="{ 'table__layout-actions': item.id === 'actions', 'table__layout-no-padding': !item.hasPadding }"
            >
              <div v-if="item.id === 'actions'" class="table__layout-actions-content">
                <slot v-bind:row="row" v-bind:header="item" v-bind:column="row[item.id]" name="bodyCell">
                  {{ row[item.id] }}
                </slot>
              </div>

              <slot v-else v-bind:row="row" v-bind:header="item" v-bind:column="row[item.id]" name="bodyCell">
                <template v-if="!Array.isArray(row[item.id])">
                  {{ row[item.id] }}
                </template>
              </slot>
            </td>
          </template>
          <template v-else>
            <td v-for="(item, index) in Object.keys(row)" :key="index">
              <slot v-bind:row="row" v-bind:header="undefined" v-bind:column="row[item]" name="bodyCell">
                <template v-if="!Array.isArray(row[item])">
                  {{ row[item] }}
                </template>
              </slot>
            </td>
          </template>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td :colspan="header ? header.length : 1">
            <slot name="empty">No results found</slot>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table__pagination" v-if="pagination && (body.length > 0 || skeletonLoader)">
      <div class="table__pagination__dropdown-area">
        <label>Rows per page:</label>
        <p-select :disabled.prop="skeletonLoader || loading" @select="handleItemsPerPage">
          <p-select-option
            v-for="option in itemsPerPageOptions"
            :key="option.value"
            :value="option.value"
            :selected.prop="option.value === String(modelValue.itemsPerPage)"
            >{{ option.text }}</p-select-option
          >
        </p-select>
      </div>
      <div class="table__pagination__navigation-buttons">
        <p-button
          v-device-desktop
          color-type="tertiary"
          size="large"
          @click="handlePrevPage"
          :disabled.prop="modelValue.currentPage === 1 || skeletonLoader || loading"
          icon="chevron-left"
        />

        <p-button
          v-device-mobile
          color-type="tertiary"
          size="small"
          @click="handlePrevPage"
          :disabled.prop="modelValue.currentPage === 1 || skeletonLoader || loading"
          icon="chevron-left"
        />

        <span>{{ paginationFrom }} - {{ `${paginationTo} of ${totalItems ? totalItems : 0}` }}</span>

        <p-button
          v-device-desktop
          color-type="tertiary"
          size="large"
          @click="handleNextPage"
          :disabled.prop="
            modelValue.currentPage - 1 >= Math.ceil(totalItems / modelValue.itemsPerPage) - 1 ||
            skeletonLoader ||
            loading
          "
          icon="chevron-right"
        />

        <p-button
          v-device-mobile
          color-type="tertiary"
          size="small"
          @click="handleNextPage"
          :disabled.prop="
            modelValue.currentPage - 1 >= Math.ceil(totalItems / modelValue.itemsPerPage) - 1 ||
            skeletonLoader ||
            loading
          "
          icon="chevron-right"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import draggable from 'vuedraggable';
export type TableHeaderSortingDirection = 'none' | 'asc' | 'desc';

export interface TableSortingState {
  column: string;
  direction: TableHeaderSortingDirection;
}

export interface TableState {
  sorting: TableSortingState;
  searchTerm: string;
  itemsPerPage: number;
  currentPage: number;
  bulkSelections: string[];
}

export type TableBody = { [key: string]: unknown };
export type TableHeader = {
  id: string;
  label: string;
  helpText?: string;
  sortable?: boolean;
  hasPadding?: boolean;
  width?: string;
  nowrap?: boolean;
  verticalAlign?: 'top' | 'middle' | 'bottom';
};

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DropdownOption } from './form/types';

@Component({
  components: {
    draggable
  },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class Table extends Vue {
  @Prop({ type: Array as PropType<TableHeader[]>, required: false, default: undefined })
  public readonly header?: TableHeader[];

  @Prop({ type: Array as PropType<TableBody[]>, required: true })
  public readonly body!: TableBody[];

  @Prop({ type: Boolean, default: false, required: false }) public readonly pagination!: boolean;
  @Prop({ type: Boolean, default: false, required: false }) public readonly searchable!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly searchableAutofocus!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly searchablePlaceholder?: string;
  @Prop({ type: Boolean, default: false, required: false }) public readonly hasBulkSelections!: boolean;
  @Prop({ type: Boolean, default: false, required: false }) public readonly skeletonLoader!: boolean;
  @Prop({ type: Number, default: undefined, required: false }) public readonly skeletonLoaderRows?: number;
  @Prop({ type: Number, default: 1, required: false }) public readonly skeletonLoaderTextLines!: number;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loading!: boolean;
  @Prop({ type: Number, required: false }) public readonly totalItems!: number;
  @Prop({ type: Boolean, default: false, required: false }) public readonly hideHeader!: boolean;
  @Prop({ type: Boolean, default: false, required: false }) public readonly overflow!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly reorder!: boolean;

  @Prop({
    type: Object as PropType<TableState>,
    default: () => ({
      sorting: {
        column: '',
        direction: 'none'
      },
      searchTerm: '',
      itemsPerPage: 10,
      currentPage: 1,
      bulkSelections: []
    })
  })
  public readonly modelValue!: TableState;

  public toggleAllBulkSelections = false;
  public itemsPerPageOptions: DropdownOption[] = [
    {
      value: '10',
      text: '10'
    },
    {
      value: '20',
      text: '20'
    },
    {
      value: '50',
      text: '50'
    },
    {
      value: '100',
      text: '100'
    }
  ];

  public endDragging($event: CustomEvent & { oldIndex: number; newIndex: number }) {
    this.setDragCursor(false);
    this.$emit('sort', $event.oldIndex, $event.newIndex);
  }

  public startDragging() {
    this.setDragCursor(true);
  }

  // https://github.com/SortableJS/Vue.Draggable/issues/815#issuecomment-808420144
  // fix issue with changing the mouse on drag cursor
  public setDragCursor(value: boolean) {
    const html = document.getElementsByTagName('html').item(0);

    if (html instanceof HTMLHtmlElement) {
      html.classList.toggle('grabbing', value);
    }
  }

  @Watch('toggleAllBulkSelections')
  public onToggleAllBulkSelectionsChange(value: boolean) {
    if (value) {
      const rowIds = this.body.map((item) => String(item.id));
      this.$emit('update:modelValue', {
        ...this.modelValue,
        bulkSelections: rowIds
      });

      this.$emit('bulk-interact');
    } else {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        bulkSelections: []
      });

      this.$emit('bulk-interact');
    }
  }

  public handleItemsPerPage(evt: CustomEvent<string[]>) {
    this.toggleAllBulkSelections = false;
    if (evt.detail[0]) {
      // we also reset the current page to 0
      // because if there is 6 items and split in 2 per page and we are on page 3
      // and change the items per page to 3, page 3 will be empty now
      this.$emit('update:modelValue', {
        ...this.modelValue,
        itemsPerPage: Number(evt.detail[0]),
        currentPage: 1,
        ...(this.hasBulkSelections && {
          bulkSelections: []
        })
      });
    }
  }

  public handleBulkSelection(id: string) {
    const bulkSelections = [...this.modelValue.bulkSelections];

    if (bulkSelections.includes(id)) {
      bulkSelections.splice(bulkSelections.indexOf(id), 1);
    } else {
      bulkSelections.push(id);
    }

    if (bulkSelections.length === 0) {
      this.toggleAllBulkSelections = false;
    } else if (bulkSelections.length === this.body.length) {
      this.toggleAllBulkSelections = true;
    }

    this.$emit('update:modelValue', {
      ...this.modelValue,
      bulkSelections: bulkSelections
    });

    this.$emit('bulk-interact');
  }

  public handleSearch(value: string) {
    this.toggleAllBulkSelections = false;
    this.$emit('update:modelValue', {
      ...this.modelValue,
      searchTerm: value,
      ...(this.hasBulkSelections && {
        bulkSelections: []
      })
    });
  }

  public handleSorting(item: TableHeader) {
    if (!item.sortable) return;

    this.toggleAllBulkSelections = false;

    let sorting: TableSortingState = {
      column: item.id,
      direction: 'none'
    };

    if (this.modelValue.sorting.column !== item.id) {
      sorting = {
        column: item.id,
        direction: 'desc'
      };
    } else {
      // If the column clicked is the current sorted column, rotate direction
      switch (this.modelValue.sorting.direction) {
        case 'desc':
          sorting.direction = 'asc';
          break;
        case 'asc':
          sorting.direction = 'none';
          break;
        default:
          sorting.direction = 'desc';
      }
    }

    this.$emit('update:modelValue', {
      ...this.modelValue,
      sorting: sorting,
      ...(this.hasBulkSelections && {
        bulkSelections: []
      })
    });
  }

  public handlePrevPage() {
    this.toggleAllBulkSelections = false;
    const currentPage = this.modelValue.currentPage;
    this.$emit('update:modelValue', {
      ...this.modelValue,
      currentPage: currentPage - 1,
      ...(this.hasBulkSelections && {
        bulkSelections: []
      })
    });
  }

  public handleNextPage() {
    this.toggleAllBulkSelections = false;
    const currentPage = this.modelValue.currentPage;
    this.$emit('update:modelValue', {
      ...this.modelValue,
      currentPage: currentPage + 1,
      ...(this.hasBulkSelections && {
        bulkSelections: []
      })
    });
  }

  public get columnStyle(): { [key: string]: { [key: string]: string } } {
    if (!this.header) {
      return {};
    }

    const styleMap: { [key: string]: { [key: string]: string } } = {};

    this.header.forEach((item) => {
      const style: { [key: string]: string } = {};

      if (item.verticalAlign) {
        style.verticalAlign = item.verticalAlign;
      }

      if (item.nowrap) {
        style.whiteSpace = 'nowrap';
      }

      if (item.width) {
        style.width = style.maxWidth = style.minWidth = item.width;
      }

      if (Object.keys(style).length > 0) {
        styleMap[item.id] = style;
      }
    });

    return styleMap;
  }

  public get sortIcon() {
    const icons: { [key: string]: string } = {};

    if (!this.header) return icons;
    this.header.forEach((item) => {
      icons[item.id] = '';
      if (!item.sortable) return icons[item.id];

      if (this.modelValue.sorting.column === item.id) {
        // Show the direction icon for the sorted column
        switch (this.modelValue.sorting.direction) {
          case 'asc':
            icons[item.id] = 'chevron-up';
            break;

          case 'desc':
            icons[item.id] = 'chevron-down';
            break;

          default:
            icons[item.id] = 'chevron-right';
        }
      } else {
        icons[item.id] = 'chevron-right';
      }
    });

    return icons;
  }

  public get paginationFrom() {
    if (this.totalItems === 0) {
      return 0;
    }

    return this.modelValue.currentPage ? (this.modelValue.currentPage - 1) * this.modelValue.itemsPerPage + 1 : 1;
  }

  public get paginationTo() {
    if (this.totalItems === 0) {
      return 0;
    }

    return this.paginationFrom + (this.body.length - 1);
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';
@import '../../scss/mixins/devices';

.grabbing * {
  cursor: grabbing !important;
}

.table {
  width: 100%;
  position: relative;

  &--reorder {
    tbody {
      td {
        cursor: grab;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: var(--gap-size-large);
  }

  &__toolbar {
    display: flex;
    align-items: flex-end;
    gap: var(--gap-size-small);
  }

  &__search {
    width: var(--field-width-medium);
  }

  &__layout {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    &-button {
      width: 25px;
      min-width: 25px;
      position: relative;
    }

    &-checkbox {
      position: absolute;
    }

    &-skeleton {
      display: flex;
      flex-direction: column;
      gap: var(--gap-size-small);
    }

    thead {
      border-bottom: 1px solid var(--color-border-decorative-inverted);

      th {
        @include component-text-headline;

        vertical-align: top;
        padding: var(--base-size-100);
        color: var(--text-color-default);

        span {
          display: flex;
          user-select: none;
        }
      }
    }

    tbody {
      td {
        @include component-text-default;

        word-break: break-word;
        vertical-align: middle;
        padding: var(--base-size-200) var(--base-size-100);
        color: var(--text-color-headline);
      }

      .table__layout-no-padding {
        padding: var(--base-size-200) 0;
      }
    }

    tr:nth-child(odd) {
      background: var(--section-background-color);
    }

    tr:nth-child(even) {
      background: var(--color-base-100);
    }

    &-actions {
      text-align: right;
      vertical-align: top;
      width: 120px;
      white-space: nowrap;

      &-content {
        display: inline-flex;
        gap: var(--gap-size-small);
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--base-size-75) 0;
    min-height: 56px;
    border-top: 1px solid var(--color-border-decorative-inverted);

    &__dropdown-area {
      display: flex;
      align-items: center;
      gap: var(--gap-size-small);

      > label {
        @include component-text-default;
      }
    }

    &__navigation-buttons {
      align-items: center;
      display: flex;

      > span {
        @include component-text-default;
      }
    }
  }

  &--skeleton {
    .table__layout tr:nth-child(even) {
      background: transparent;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 230px;
  }
}

@include for-mobile-only {
  .table {
    overflow-x: auto;
    overflow-y: visible;

    &__header {
      flex-wrap: wrap;
      gap: var(--gap-size-small);
    }

    &__search {
      width: 100%;
    }

    &__toolbar {
      flex-direction: column;
      width: 100%;
    }

    &__pagination {
      white-space: nowrap;
    }

    td:not(.table__layout-button),
    th:not(.table__layout-button) {
      white-space: nowrap;
      width: auto !important;
      max-width: none !important;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.sortable-chosen {
  background: var(--color-background-layer-1);
  cursor: grabbing;
}

.sortable-ghost {
  opacity: 0;
}
</style>
